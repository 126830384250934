import React, {useEffect, useRef, useCallback} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {APICall} from '../../api/api';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles/index';
import {useMediaQuery} from 'react-responsive';
import millify from 'millify';
import {findFlagUrlByCountryName} from 'country-flags-svg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FadeIn from 'react-fade-in';
import {PeriodPicker} from './PeriodPicker';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import breakpoints from './../helpers/breakpoints';

import useStore from '../../store/store';

const StyledTableCell = withStyles(theme => ({
  head: {
    background: theme.palette.graphColors.themeDarkGray,
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
  },
  body: {
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  buttons: {
    margin: theme.spacing(2),
  },
  sorted: {
    background: theme.palette.graphColors.sorted,
  },
}));

const GeoView = React.memo(props => {
  //console.log('geoView props', props);
  const isMobile = useMediaQuery({query: breakpoints.mobile});
  const classes = useStyles();
  const [geoData, setGeoData] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [sort, setSort] = React.useState({key: 'rev', direction: 'down'});
  const abortController = new AbortController();

  const selection = useStore(state => state.selection);
  const period = useStore(state => state.period);

  const hideViewabilityCTR = [4, 5, 6, 7].includes(props.login.type);

  const theme = useTheme();

  useEffect(() => {
    setLoading(true);
    setGeoData(false);
    getGeoData();

    return () => abortController.abort();
  }, [selection.value, period]);

  const getGeoData = async () => {
    let data = await APICall({
      endpoint: 'getGeoData',
      options: {
        method: 'POST',
        body: JSON.stringify({
          selection: selection,
          period: period,
        }),
        headers: {
          Authorization: `Bearer ${props.login.token}`,
          'Cache-Control': 'max-age=0, no-cache',
          'Content-Type': 'application/json',
        },
      },
    });
    setLoading(false);
    if (data.success) {
      setError(null);
      setGeoData(data.geoData);
    } else {
      setError(data.error ?? 'Error getting Geo data');
    }
  };

  const changeSort = key => {
    setSort(prevSort => {
      return {
        key: key,
        direction: prevSort.direction === 'down' ? 'up' : 'down',
      };
    });
  };

  const generateRevenueBySSPTable = () => {
    // Get total impressions from our database
    let impressionsWithoutRefreshes = props.totalImpressions;

    // Get total revenue
    let totalRevenue = geoData.reduce((total, country) => total + country.rev, 0).toFixed(2);

    // Get total revenue from props
    let totalRevenueFromDailyTab = props.totalRevenue;

    // Calculate revenue share
    let revRatio = totalRevenue / totalRevenueFromDailyTab;

    // Get total impressions from google report
    let totalImpressions = geoData.reduce((total, country) => total + country.imp, 0).toFixed(2);

    // Calculate how much times more impressions there are without refreshes
    let impRatio = totalImpressions / impressionsWithoutRefreshes;

    // Add recalculations
    let data = geoData.map(country => {
      let adjustedImpressions = country.imp / impRatio;
      let adjustedRev = country.rev / revRatio;
      return {
        ...country,
        rev: adjustedRev,
        imp: adjustedImpressions,
        eCPM: (country.rev / adjustedImpressions) * 1000,
        revShare: ((adjustedRev * 100) / totalRevenue).toFixed(2),
        impShare: ((adjustedImpressions * 100) / props.totalImpressions).toFixed(2),
      };
    });

    // Get totals for table
    totalImpressions = data.reduce((total, country) => total + country.imp, 0).toFixed(2);

    // Sort data
    let sortedData = Object.entries(data).sort((a, b) => {
      return sort.direction === 'down'
        ? b[1][sort.key] - a[1][sort.key]
        : a[1][sort.key] - b[1][sort.key];
    });

    if (props.login.username === 'demo') {
      sortedData = sortedData.slice(0, 10);
    }

    return (
      <>
        <Typography variant={'h6'} style={{marginBottom: 10}} align={'left'}>
          Distribution by geolocation
          <Tooltip arrow interactive title={`Grouped according to user geoloaction (IP) data. `}>
            <InfoIcon
              style={{opacity: 0.5, left: 5, top: 4, position: 'relative'}}
              fontSize={'small'}
            />
          </Tooltip>
        </Typography>

        <Grid item xs={12} style={{marginBottom: 15, textAlign: 'left'}}>
          <PeriodPicker isMobile={isMobile} />
        </Grid>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{fontWeight: 'bold'}}>Country</StyledTableCell>
                <StyledTableCell
                  onClick={() => changeSort('eCPM')}
                  align="right"
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    borderBottom: `3px solid ${theme.palette.graphColors.eCPM}`,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexWrap: `wrap`,
                    }}
                  >
                    {sort.key === 'eCPM' ? (
                      sort.direction === 'up' ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : (
                      <ExpandLessIcon style={{opacity: 0}} />
                    )}
                    eCPM
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => changeSort('imp')}
                  align="right"
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    borderBottom: `3px solid ${theme.palette.graphColors.themeDarkGray}`,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexWrap: `wrap`,
                    }}
                  >
                    {sort.key === 'imp' ? (
                      sort.direction === 'up' ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : (
                      <ExpandLessIcon style={{opacity: 0}} />
                    )}
                    Impressions
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => changeSort('impShare')}
                  align="right"
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    borderBottom: `3px solid ${theme.palette.graphColors.themeDarkGray}`,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexWrap: `wrap`,
                    }}
                  >
                    {sort.key === 'impShare' ? (
                      sort.direction === 'up' ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : (
                      <ExpandLessIcon style={{opacity: 0}} />
                    )}
                    Impressions share
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => changeSort('rev')}
                  align="right"
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    borderBottom: `3px solid ${theme.palette.graphColors.revenue}`,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexWrap: `wrap`,
                    }}
                  >
                    {sort.key === 'rev' ? (
                      sort.direction === 'up' ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : (
                      <ExpandLessIcon style={{opacity: 0}} />
                    )}
                    Revenue
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => changeSort('revShare')}
                  align="right"
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexWrap: `wrap`,
                    }}
                  >
                    {sort.key === 'revShare' ? (
                      sort.direction === 'up' ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : (
                      <ExpandLessIcon style={{opacity: 0}} />
                    )}
                    Revenue share
                  </div>
                </StyledTableCell>
                {!hideViewabilityCTR && (
                  <>
                    <StyledTableCell
                      onClick={() => changeSort('viewability')}
                      align="right"
                      style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        borderBottom: `3px dashed ${theme.palette.graphColors.viewability}`,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          flexWrap: `wrap`,
                        }}
                      >
                        {sort.key === 'viewability' ? (
                          sort.direction === 'up' ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )
                        ) : (
                          <ExpandLessIcon style={{opacity: 0}} />
                        )}
                        Viewability
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() => changeSort('ctr')}
                      align="right"
                      style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        borderBottom: `3px dashed ${theme.palette.graphColors.CTR}`,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          flexWrap: `wrap`,
                        }}
                      >
                        {sort.key === 'ctr' ? (
                          sort.direction === 'up' ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )
                        ) : (
                          <ExpandLessIcon style={{opacity: 0}} />
                        )}
                        CTR
                      </div>
                    </StyledTableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map(row => {
                return (
                  <TableRow key={row[0]}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{textTransform: 'capitalize'}}
                    >
                      {row[1].country ? (
                        <img
                          src={findFlagUrlByCountryName(
                            countryToFlagMappingCorrections(row[1].country)
                          )}
                          style={{
                            width: '24px',
                            position: 'relative',
                            top: 2,
                            marginRight: 7,
                          }}
                        />
                      ) : (
                        ''
                      )}
                      {row[1].country}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      className={sort.key === 'eCPM' ? classes.sorted : ''}
                    >
                      {millify(row[1].eCPM)}€
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      className={sort.key === 'imp' ? classes.sorted : ''}
                    >
                      {millify(row[1].imp.toFixed())}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      className={sort.key === 'impShare' ? classes.sorted : ''}
                    >
                      {row[1].impShare}%
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      className={sort.key === 'rev' ? classes.sorted : ''}
                    >
                      {row[1].rev.toFixed(2)}€
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      className={sort.key === 'revShare' ? classes.sorted : ''}
                    >
                      {row[1].revShare}%
                    </StyledTableCell>
                    {!hideViewabilityCTR && (
                      <>
                        <StyledTableCell
                          align="right"
                          className={sort.key === 'viewability' ? classes.sorted : ''}
                        >
                          {millify(row[1].viewability)}%
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          className={sort.key === 'ctr' ? classes.sorted : ''}
                        >
                          {millify(row[1].ctr)}%
                        </StyledTableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell style={{fontWeight: 'bold'}} component="th" scope="row">
                  Total
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" />
                <TableCell style={{fontWeight: 'bold'}} align="right">
                  {millify(totalImpressions)}
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" />
                <TableCell style={{fontWeight: 'bold'}} align="right">
                  {totalRevenueFromDailyTab}€
                </TableCell>
                {!hideViewabilityCTR && (
                  <>
                    <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" />
                    <TableCell style={{fontWeight: 'bold'}} component="th" scope="row" />
                  </>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const countryToFlagMappingCorrections = countryName => {
    // GAM API returns country codes which we map to flag svgs using country-flags-svg library.
    // Some names doesnt match the ones used in the libarary, so we do mapping correction here.
    // https://github.com/ronatskiy/country-flags-svg/blob/master/src/data/countries.ts

    const corrections = {
      Turkiye: 'Turkey',
      Czechia: 'Czech Republic',
      'Myanmar (Burma)': 'Myanmar',
    };

    if (corrections[countryName]) {
      return corrections[countryName];
    } else return countryName;
  };

  return (
    <div style={{padding: '0 40px 40px 40px', width: '100%', textAlign: 'center'}}>
      {(!geoData || props.loadingTotals) && !error && (
        <>
          <FadeIn>
            <Typography variant={'caption'}>Loading distribution by geolocation</Typography>
            <br />
            <CircularProgress color="primary" />
          </FadeIn>
        </>
      )}
      {error && (
        <>
          <Typography variant={'caption'}>{error}</Typography>
        </>
      )}
      {geoData && !props.loadingTotals && (
        <>
          <FadeIn>
            <>{generateRevenueBySSPTable()}</>
          </FadeIn>
        </>
      )}
    </div>
  );
});

export default React.memo(GeoView);
